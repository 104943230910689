.@{steps-prefix-cls}-label-vertical {
  .@{steps-prefix-cls}-item {
    overflow: visible;
    &-tail {
      margin-left: 51px;
      padding: 3.5px 24px;
    }
    &-content {
      display: block;
      // icon左边距离+一半icon宽度，是content一半的宽度，垂直对齐icon
      width: (@steps-icon-size / 2 + 36px) * 2;
      margin-top: 8px;
      text-align: center;
    }
    &-icon {
      display: inline-block;
      margin-left: 36px;
    }
    &-title {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
  }
  &.@{steps-prefix-cls}-small {
    .@{steps-prefix-cls}-item {
      &-icon {
        margin-left: 40px;
      }
    }
  }
}
