@import '../../style/themes/default';
@import '../../style/mixins/index';

@upload-prefix-cls: ~'@{ant-prefix}-upload';
@upload-item: ~'@{ant-prefix}-upload-list-item';
@upload-picture-card-size: 104px;
@upload-picture-card-border-style: @border-style-base;

.@{upload-prefix-cls} {
  .reset-component;
  outline: 0;

  p {
    margin: 0;
  }

  &-btn {
    display: block;
    width: 100%;
    outline: none;
  }

  input[type='file'] {
    cursor: pointer;
  }

  &&-select {
    display: inline-block;
  }

  &&-select-picture-card {
    display: table;
    width: @upload-picture-card-size;
    height: @upload-picture-card-size;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: @background-color-light;
    border: @border-width-base dashed @border-color-base;
    border-radius: @border-radius-base;
    cursor: pointer;
    transition: border-color 0.3s ease;

    > .@{upload-prefix-cls} {
      display: table-cell;
      width: 100%;
      height: 100%;
      padding: 8px;
      text-align: center;
      vertical-align: middle;
    }

    &:hover {
      border-color: @primary-color;
    }
  }

  &&-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: @background-color-light;
    border: @border-width-base dashed @border-color-base;
    border-radius: @border-radius-base;
    cursor: pointer;
    transition: border-color 0.3s;

    .@{upload-prefix-cls} {
      padding: 16px 0;
    }

    &.@{upload-prefix-cls}-drag-hover:not(.@{upload-prefix-cls}-disabled) {
      border: 2px dashed @primary-5;
    }

    &.@{upload-prefix-cls}-disabled {
      cursor: not-allowed;
    }

    .@{upload-prefix-cls}-btn {
      display: table;
      height: 100%;
    }

    .@{upload-prefix-cls}-drag-container {
      display: table-cell;
      vertical-align: middle;
    }

    &:not(.@{upload-prefix-cls}-disabled):hover {
      border-color: @primary-5;
    }

    p.@{upload-prefix-cls}-drag-icon {
      .@{iconfont-css-prefix} {
        color: @primary-5;
        font-size: 48px;
      }
      margin-bottom: 20px;
    }
    p.@{upload-prefix-cls}-text {
      margin: 0 0 4px;
      color: @heading-color;
      font-size: @font-size-lg;
    }
    p.@{upload-prefix-cls}-hint {
      color: @text-color-secondary;
      font-size: @font-size-base;
    }
    .@{iconfont-css-prefix}-plus {
      color: @disabled-color;
      font-size: 30px;
      transition: all 0.3s;
      &:hover {
        color: @text-color-secondary;
      }
    }
    &:hover .@{iconfont-css-prefix}-plus {
      color: @text-color-secondary;
    }
  }
}

.@{upload-prefix-cls}-list {
  .reset-component;
  .clearfix;
  &-item {
    position: relative;
    height: 22px;
    margin-top: 8px;
    font-size: @font-size-base;
    &-name {
      display: inline-block;
      width: 100%;
      padding-left: @font-size-base + 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-info {
      height: 100%;
      padding: 0 12px 0 4px;
      transition: background-color 0.3s;

      > span {
        display: block;
      }

      .@{iconfont-css-prefix}-loading,
      .@{iconfont-css-prefix}-paper-clip {
        position: absolute;
        top: @font-size-base / 2 - 2px;
        color: @text-color-secondary;
        font-size: @font-size-base;
      }
    }

    .@{iconfont-css-prefix}-close {
      .iconfont-size-under-12px(10px);
      position: absolute;
      top: 6px;
      right: 4px;
      color: @text-color-secondary;
      line-height: 0;
      cursor: pointer;
      opacity: 0;
      transition: all 0.3s;
      &:hover {
        color: @text-color;
      }
    }

    &:hover &-info {
      background-color: @item-hover-bg;
    }

    &:hover .@{iconfont-css-prefix}-close {
      opacity: 1;
    }

    &-error,
    &-error .@{iconfont-css-prefix}-paper-clip,
    &-error &-name {
      color: @error-color;
    }

    &-error .@{iconfont-css-prefix}-close {
      color: @error-color !important;
      opacity: 1;
    }

    &-progress {
      position: absolute;
      bottom: -12px;
      width: 100%;
      padding-left: @font-size-base + 12px;
      font-size: @font-size-base;
      line-height: 0;
    }
  }

  &-picture,
  &-picture-card {
    .@{upload-item} {
      position: relative;
      height: 66px;
      padding: 8px;
      border: @border-width-base @upload-picture-card-border-style @border-color-base;
      border-radius: @border-radius-base;
      &:hover {
        background: transparent;
      }
      &-error {
        border-color: @error-color;
      }
    }

    .@{upload-item}-info {
      padding: 0;
    }

    .@{upload-item}:hover .@{upload-item}-info {
      background: transparent;
    }

    .@{upload-item}-uploading {
      border-style: dashed;
    }

    .@{upload-item}-thumbnail {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 48px;
      height: 48px;
      font-size: 26px;
      line-height: 54px;
      text-align: center;
      opacity: 0.8;
    }

    .@{upload-item}-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 26px;
      transform: translate(-50%, -50%);
    }

    .@{upload-item}-thumbnail img {
      display: block;
      width: 48px;
      height: 48px;
      overflow: hidden;
    }

    .@{upload-item}-name {
      display: inline-block;
      box-sizing: border-box;
      max-width: 100%;
      margin: 0 0 0 8px;
      padding-right: 8px;
      padding-left: 48px;
      overflow: hidden;
      line-height: 44px;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: all 0.3s;
    }

    .@{upload-item}-uploading .@{upload-item}-name {
      line-height: 28px;
    }

    .@{upload-item}-progress {
      bottom: 14px;
      width: ~'calc(100% - 24px)';
      margin-top: 0;
      padding-left: 56px;
    }

    .@{iconfont-css-prefix}-close {
      position: absolute;
      top: 8px;
      right: 8px;
      line-height: 1;
      opacity: 1;
    }
  }

  &-picture-card {
    // https://github.com/ant-design/ant-design/issues/11183
    float: left;

    &.@{upload-prefix-cls}-list::after {
      display: none;
    }
    .@{upload-item} {
      float: left;
      width: @upload-picture-card-size;
      height: @upload-picture-card-size;
      margin: 0 8px 8px 0;
    }

    .@{upload-item}-info {
      position: relative;
      height: 100%;
      overflow: hidden;

      &::before {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: fade(@black, 50%);
        opacity: 0;
        transition: all 0.3s;
        content: ' ';
      }
    }

    .@{upload-item}:hover .@{upload-item}-info::before {
      opacity: 1;
    }

    .@{upload-item}-actions {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;
      white-space: nowrap;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.3s;

      .@{iconfont-css-prefix}-eye-o,
      .@{iconfont-css-prefix}-delete {
        z-index: 10;
        width: 16px;
        margin: 0 4px;
        color: @text-color-dark;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          color: @text-color-inverse;
        }
      }
    }

    .@{upload-item}-info:hover + .@{upload-item}-actions,
    .@{upload-item}-actions:hover {
      opacity: 1;
    }

    .@{upload-item}-thumbnail,
    .@{upload-item}-thumbnail img {
      position: static;
      display: block;
      width: 100%;
      height: 100%;
    }

    .@{upload-item}-name {
      display: none;
      margin: 8px 0 0;
      padding: 0;
      line-height: @line-height-base;
      text-align: center;
    }

    .anticon-picture + .@{upload-item}-name {
      display: block;
    }

    .@{upload-item}-uploading {
      &.@{upload-item} {
        background-color: @background-color-light;
      }

      .@{upload-item}-info {
        height: auto;
        &::before,
        .@{iconfont-css-prefix}-eye-o,
        .@{iconfont-css-prefix}-delete {
          display: none;
        }
      }

      &-text {
        margin-top: 18px;
        color: @text-color-secondary;
      }
    }

    .@{upload-item}-progress {
      bottom: 32px;
      padding-left: 0;
    }
  }

  .@{upload-prefix-cls}-success-icon {
    color: @success-color;
    font-weight: bold;
  }

  .@{upload-prefix-cls}-animate-enter,
  .@{upload-prefix-cls}-animate-leave,
  .@{upload-prefix-cls}-animate-inline-enter,
  .@{upload-prefix-cls}-animate-inline-leave {
    animation-duration: 0.3s;
    animation-fill-mode: @ease-in-out-circ;
  }

  .@{upload-prefix-cls}-animate-enter {
    animation-name: uploadAnimateIn;
  }

  .@{upload-prefix-cls}-animate-leave {
    animation-name: uploadAnimateOut;
  }

  .@{upload-prefix-cls}-animate-inline-enter {
    animation-name: uploadAnimateInlineIn;
  }

  .@{upload-prefix-cls}-animate-inline-leave {
    animation-name: uploadAnimateInlineOut;
  }
}

@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
